<template>
  <v-app id="app">
    <v-app-bar
      class="app-bar"
      app
      flat
      color="transparent"
      height="64px"
      max-width="1400px"
    >
      <div class="app-bar-mask"></div>
      <a class="logo text-decoration-none" href="/">
        <img src="@/assets/logo.png" alt="logo" />
        <span class="logo-text">{{ tenantName ?? "" }}</span>
      </a>

      <v-spacer></v-spacer>

      <v-tabs class="mr-10" right dark>
        <v-tab to="/index">首页</v-tab>
        <v-tab to="/positions">岗位投递</v-tab>
        <v-tab v-if="isSchool" to="/infomation">招聘动态</v-tab>
        <v-tab to="/about">视觉赛克</v-tab>
        <template v-if="!!token && isAdmin">
          <v-tab to="/admin/resumes">简历</v-tab>
          <v-tab to="/admin/positions">发布岗位</v-tab>
          <v-tab v-if="isSchool" to="/admin/position-providers"
            >第三方对接</v-tab
          >
        </template>
        <template v-if="!!token && isSuperAdmin">
          <v-tab to="/admin/accounts">员工</v-tab>
        </template>
      </v-tabs>

      <v-dialog max-width="500px" v-if="!token">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="login-btn"
            outlined
            color="white"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-account</v-icon>
            <span>登录/注册</span>
          </v-btn>
        </template>

        <v-img class="card-header" src="@/assets/login_header.png"></v-img>
        <v-card class="mt-n1" outlined>
          <v-card-title>
            <span class="card-title headline mt-n9">登录/注册</span>
          </v-card-title>

          <v-form class="card-form" ref="form">
            <v-text-field
              v-model="formData.phoneNumber"
              :rules="rules.phoneNumber"
              label="请输入手机号码"
              outlined
              rounded
              solo
              flat
              dense
              prepend-inner-icon="mdi-phone"
              autofocus
            ></v-text-field>
            <v-text-field
              class="input-group"
              v-model="formData.code"
              :rules="rules.code"
              label="请输入验证码"
              outlined
              rounded
              solo
              flat
              dense
              prepend-inner-icon="mdi-key"
              @keyup.enter="phoneSignin"
            >
              <template v-slot:append-outer>
                <v-btn
                  color="accent"
                  rounded
                  solo
                  dense
                  min-width="150px"
                  :elevation="0"
                  :disabled="!!codeLoading"
                  :loading="!!codeLoading"
                  @click="sendPhoneSigninCode"
                >
                  获取验证码
                  <template v-slot:loader>
                    <span
                      v-if="!!codeLoading && typeof codeLoading === 'boolean'"
                      >正在发送验证码...</span
                    >
                    <span
                      v-if="!!codeLoading && typeof codeLoading !== 'boolean'"
                      >已发送...
                      {{ !!codeLoading ? ` (${codeLoading}s)` : "" }}</span
                    >
                  </template>
                </v-btn>
              </template>
            </v-text-field>

            <v-btn
              class="mt-5"
              block
              rounded
              color="primary"
              :disabled="!!loginLoading"
              :loading="!!loginLoading"
              @click="phoneSignin"
            >
              登录/注册
            </v-btn>
          </v-form>
        </v-card>
      </v-dialog>

      <v-menu v-if="!!token" bottom auto offset-x offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark v-bind="attrs" v-on="on">
            <v-avatar>
              <v-icon>mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            v-if="isAdmin || isSuperAdmin"
            to="/admin/account/change-password"
          >
            <v-list-item-icon>
              <v-icon>mdi-lock-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>修改密码</v-list-item-title>
          </v-list-item>
          <v-list-item to="/resume/mine">
            <v-list-item-icon>
              <v-icon>mdi-account-edit-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>我的简历</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>退出登录</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main class="">
      <router-view />
    </v-main>

    <v-footer dark padless>
      <v-card class="flex" flat tile>
        <v-card-text
          class="py-2 grey--text text-center d-md-flex justify-center"
        >
          <div class="mx-3">
            <a
              class="grey--text text-decoration-none"
              href="http://www.sinovatio.com/"
              >中新赛克官网</a
            >
          </div>
          <div class="mx-3">
            Copyright © 2021 Sinovatio Technology Co.Ltd All rights reserved.
          </div>

          <div class="mx-3">
            <a
              class="grey--text text-decoration-none"
              href="https://beian.miit.gov.cn/"
              >粤ICP备17140951号</a
            >
          </div>
          <!-- <span class="mx-3">
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011402010786"
              class="grey--text text-decoration-none"
              >苏公网安备 32011402010786号
            </a>
          </span> -->
        </v-card-text>
      </v-card>
    </v-footer>
    <!-- <v-dialog v-model="promiseVisible" max-width="50%" persistent>
      <v-card
        class="promise-dialog"
        :loading="promiseLoading"
        :disabled="promiseLoading"
      >
        <v-system-bar window color="#F4F7FD">
          <v-icon>mdi-message</v-icon>郑重声明
          <v-spacer></v-spacer>
        </v-system-bar>
        <v-divider></v-divider>

        <v-card-text style="height: 34rem; padding: 2rem">
          <p>
            本人郑重声明：填报信息全部属实，并愿意接受相关背景核查。若填报事项与事实不符，本人愿意承担由此引起的责任及后果。
          </p>
          <v-form ref="promiseForm" lazy-validation>
            <p class="d-flex mb-0">
              <v-radio-group
                v-model="promiseData.isInternalReferral"
                :rules="[(v) => v != null || '请确认是否公司内部人员推荐']"
                label="是否公司内部人员推荐："
                row
                outlined
              >
                <v-radio label="是" :value="true"></v-radio>
                <v-radio label="否" :value="false"></v-radio>
              </v-radio-group>
              <v-text-field
                v-model="promiseData.referrer"
                :disabled="!promiseData.isInternalReferral"
                :rules="[
                  (v) =>
                    promiseData.isInternalReferral !== true ||
                    !!v ||
                    '请填写推荐人姓名',
                ]"
                label="推荐人姓名"
                underlined
                dens
              >
              </v-text-field>
              <v-text-field
                v-model="promiseData.referrerRelationship"
                :disabled="!promiseData.isInternalReferral"
                :rules="[
                  (v) =>
                    promiseData.isInternalReferral !== true ||
                    !!v ||
                    '请填写推荐人关系',
                ]"
                label="与推荐人关系"
                underlined
                dens
              >
              </v-text-field>
              <v-spacer></v-spacer>
            </p>
            <p class="d-flex mb-0">
              <v-radio-group
                v-model="promiseData.isRelativeOrFriendReferral"
                :rules="[(v) => v != null || '请确认是否公司内部人员推荐']"
                label="是否有亲属或朋友在中新赛克："
                row
                outlined
              >
                <v-radio label="是" :value="true"></v-radio>
                <v-radio label="否" :value="false"></v-radio>
              </v-radio-group>
              <v-text-field
                v-model="promiseData.relativeOrFriend"
                :disabled="!promiseData.isRelativeOrFriendReferral"
                :rules="[
                  (v) =>
                    promiseData.isRelativeOrFriendReferral !== true ||
                    !!v ||
                    '请填写亲属或朋友姓名',
                ]"
                label="姓名"
                underlined
                dens
              >
              </v-text-field>
              <v-text-field
                v-model="promiseData.relativeOrFriendRelationship"
                :disabled="!promiseData.isRelativeOrFriendReferral"
                :rules="[
                  (v) =>
                    promiseData.isRelativeOrFriendReferral !== true ||
                    !!v ||
                    '请填写关系',
                ]"
                label="关系"
                underlined
                dens
              >
              </v-text-field>
              <v-spacer></v-spacer>
            </p>
            <p class="d-flex mb-0">
              <v-radio-group
                v-model="promiseData.isFamilyAbroad"
                :rules="[
                  (v) =>
                    v != null ||
                    '请确认是否直系亲属有外国国籍、境外永久居留权或者长期居留许可',
                ]"
                label="是否有直系亲属（父母/配偶/年满18周岁子女）有外国国籍、境外永久居留权或者长期居留许可（含港澳台）："
                row
                outlined
              >
                <v-radio label="是" :value="true"></v-radio>
                <v-radio label="否" :value="false"></v-radio>
              </v-radio-group>
            </p>
            <p class="d-flex mb-0">
              <v-radio-group
                v-model="promiseData.isSecretRelated"
                :rules="[(v) => v != null || '请确认是否在上家单位为涉密人员']"
                label="本人在求职我司前的上家单位，是否为涉密人员："
                row
                outlined
              >
                <v-radio label="是" :value="true"></v-radio>
                <v-radio label="否" :value="false"></v-radio>
              </v-radio-group>
              <v-radio-group
                v-model="promiseData.isDecryptionPeriod"
                :disabled="!promiseData.isSecretRelated"
                :rules="[
                  (v) =>
                    promiseData.isSecretRelated !== true ||
                    v != null ||
                    '请确认是否处于脱密期管理',
                ]"
                label="是否处于脱密期管理："
                row
                outlined
              >
                <v-radio label="是" :value="true"></v-radio>
                <v-radio label="否" :value="false"></v-radio>
              </v-radio-group>
            </p>
            <p class="d-flex mb-0">
              <v-radio-group
                v-model="promiseData.isInDispute"
                :rules="[(v) => v != null || '请确认是否与上家单位存在纠纷']"
                label="是否与上家单位存在纠纷："
                row
                outlined
              >
                <v-radio label="是" :value="true"></v-radio>
                <v-radio label="否" :value="false"></v-radio>
              </v-radio-group>
            </p>
            <p>备注：</p>
            <ol>
              <li>亲属包括父母、配偶、子女、兄弟姐妹等；</li>
              <li>
                公司禁止在本公司有亲属（包括男、女朋友）关系的候选人参加面试，若本人在应聘时隐瞒不报亲属关系，录用后公司有权予以解除劳动合同。
              </li>
            </ol>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.stop="promise">我确认</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { getApplicationConfiguration } from "@/api/abp";
// import { create, findMyPromise } from "@/api/promise";
import { setLanguage } from "./locales";

export default {
  data: () => ({
    loader: null,
    codeLoading: false,
    loginLoading: false,
    // title: "",
    // type: "school",

    formData: {
      phoneNumber: "",
      code: "",
    },
    promiseVisible: false,
    promiseLoading: false,
    promiseData: {
      isInternalReferral: null,
      referrer: "",
      referrerRelationship: "",
      isRelativeOrFriendReferral: null,
      relativeOrFriend: "",
      relativeOrFriendRelationship: "",
      isFamilyAbroad: null,
      isSecretRelated: null,
      isDecryptionPeriod: null,
      isInDispute: null,
    },
    rules: {
      phoneNumber: [
        (v) => !!v || "请填写手机号",
        (v) =>
          v === "admin" ||
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
            v
          ) ||
          "请填写正确的手机号",
      ],
      code: [
        (v) => !!v || "请填写验证码",
        (v) => /\d{6}/.test(v) || "请填写正确的验证码",
      ],
    },
  }),
  computed: {
    ...mapState("account", {
      token: (state) => {
        return state.token;
      },
      name: (state) => {
        return state.name;
      },
      phoneNumber: (state) => {
        return state.phoneNumber;
      },
      tenantName: (state) => {
        return /social/.test(state.tenantName)
          ? "社会招聘"
          : /school/.test(state.tenantName)
          ? "校园招聘"
          : "";
      },
      isSchool: (state) => {
        return (
          state.tenantName === "recruit" || /school/.test(state.tenantName)
        );
      },
      isSocial: (state) => {
        return /social/.test(state.tenantName);
      },
      isAdmin: (state) => {
        return (
          state.roles.indexOf("admin") !== -1 && state.username !== "admin"
        );
      },
      isSuperAdmin: (state) => {
        return state.username === "admin";
      },
    }),
  },
  watch: {
    loader() {
      const l = this.loader;

      if (this.$refs.form.validate()) {
        this[l] = 60;

        const timer = setInterval(() => {
          if (this[l] > 0) {
            this[l] -= 1;
          } else {
            this[l] = false;
            clearInterval(timer);
          }
        }, 1000);
      }

      this.loader = null;
    },
  },
  async mounted() {
    const { localization, currentTenant } = await getApplicationConfiguration();
    if (localization && localization.values) {
      const messages = {};
      for (const resource in localization.values) {
        Object.assign(messages, localization.values[resource]);
      }
      setLanguage("zh-Hans", messages);
    }

    if (currentTenant.isAvailable) {
      // this.title = currentTenant.name;
      // this.type = currentTenant.name.includes("校") ? "school" : "social";

      this.$store.dispatch("account/setTenantName", currentTenant.name);
    } else {
      this.$store.dispatch("account/setTenantName", "");
    }

    // if (
    //   this.isSocial &&
    //   currentUser.isAuthenticated &&
    //   currentUser.roles.indexOf("admin") === -1
    // ) {
    //   const promise = await findMyPromise();
    //   if (!promise) {
    //     this.promiseVisible = true;
    //   }
    // }
  },
  methods: {
    validate(containsCode) {
      this.rules.code.splice(0, 2);
      if (containsCode) {
        this.rules.code.push((v) => !!v || "请填写验证码");
        this.rules.code.push((v) => /\d{6}/.test(v) || "请填写正确的验证码");
      }
      return this.$refs.form.validate();
    },
    reset() {
      this.formData.phoneNumber = "";
      this.formData.code = "";
      this.codeLoading = false;
      this.loginLoading = false;
    },
    async phoneSignin() {
      if (this.validate(true)) {
        this.loader = "loginLoading";

        try {
          await this.$store.dispatch("account/phoneSignin", this.formData);
          await this.$store.dispatch("account/getInfo");

          // const currentUser = this.$store.state.account;
          // if (this.isSocial && currentUser.roles.indexOf("admin") === -1) {
          //   const promise = await findMyPromise();
          //   if (!promise) {
          //     this.promiseVisible = true;
          //   }
          // }
        } finally {
          this.loginLoading = false;
        }
      }
    },
    async sendPhoneSigninCode() {
      if (this.validate(false)) {
        this.codeLoading = true;

        try {
          await this.$store.dispatch(
            "account/sendPhoneSigninCode",
            this.formData
          );
          this.loader = "codeLoading";
        } catch (e) {
          this.codeLoading = false;
        }
      }
    },
    async logout() {
      await this.$store.dispatch("account/logout");
      this.reset();
      this.$router.push("/");
    },
    // async promise() {
    //   if (this.$refs.promiseForm.validate()) {
    //     this.promiseLoading = true;
    //     try {
    //       await create(this.promiseData);
    //       this.promiseVisible = false;
    //     } finally {
    //       this.promiseLoading = false;
    //     }
    //   }
    // },
  },
};
</script>

<style scoped>
#app {
  background-color: #f7f7f7;
}

.app-bar {
  margin: 0 auto;
}

.app-bar-mask {
  position: fixed;
  margin-left: -2000px;
  width: 4000px;
  height: 100%;
  background-color: black;
  opacity: 0.3;
}

.logo {
  margin: auto 15px auto 0;
  min-width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 9;
}

.logo-text {
  font-size: 18px;
  font-weight: 500;
  color: white;
  letter-spacing: 1.33px;
  white-space: nowrap;
  z-index: 9;
}

.login-btn {
  margin: auto 20px auto 0;
}

.card-header {
  z-index: 1;
}

.card-title {
  padding-left: 15px;
  z-index: 2;
}

.card-form {
  padding: 60px !important;
}

.input-group >>> .v-input__slot {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group >>> .v-input__append-outer {
  margin: 0;
  margin-top: 0 !important;
}

.input-group.v-input--dense >>> .v-input__append-outer > .v-btn {
  height: 40px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.v-application >>> .v-small-dialog__content {
  padding: 0;
  overflow: hidden;
}
</style>
