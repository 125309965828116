import request from "@/utils/request";

const BASE_API = "/api/abp/application-configuration";

export function getApplicationConfiguration() {
  return request({
    url: BASE_API,
    method: "get",
  });
}
