import { setInteractionMode, extend } from "vee-validate";
import locale from "vee-validate/dist/locale/zh_CN.json";
import * as rules from "vee-validate/dist/rules";

export default {
  install() {
    setInteractionMode("eager");
    Object.keys(rules).forEach((rule) => {
      extend(rule, {
        ...rules[rule],
        message: locale.messages[rule],
      });
    });
  },
};
