import Cookies from "js-cookie";
import {
  phoneSignin,
  sendPhoneSigninCode,
  getInfo,
  login,
  logout,
} from "@/api/account";
import { resetRouter } from "@/router";
import abp from "@/utils/abp";

const TokenKey = "Recruit.Identity.Application";

const getDefaultState = () => {
  const token = Cookies.get(TokenKey);
  // if (!token) {
  //   Cookies.remove(abp.security.antiForgery.tokenCookieName);
  // }
  return {
    token,
    userId: "",
    username: "",
    name: "",
    roles: [],
    phoneNumber: "",
    tenantName: "",
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Cookies.remove(TokenKey);
    Cookies.remove(abp.security.antiForgery.tokenCookieName);
    Object.assign(state, getDefaultState(), { tenantName: state.tenantName });
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
    //Cookies.set(TokenKey, token);
  },
  SET_USERID: (state, id) => {
    state.userId = id;
  },
  SET_USERNAME: (state, userName) => {
    state.username = userName;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_PHONE: (state, phoneNumber) => {
    state.phoneNumber = phoneNumber;
  },
  SET_TENANT_NAME: (state, tenantName) => {
    state.tenantName = tenantName;
  },
};

const actions = {
  phoneSignin({ commit }, userInfo) {
    const { phoneNumber, code } = userInfo;
    return new Promise((resolve, reject) => {
      phoneSignin({ phoneNumber, code })
        .then(() => {
          commit("SET_TOKEN", Cookies.get(TokenKey));
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendPhoneSigninCode(_, userInfo) {
    const { phoneNumber } = userInfo;
    return new Promise((resolve, reject) => {
      sendPhoneSigninCode({ phoneNumber })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((data) => {
          if (!data) {
            reject("Verification failed, please Login again.");
          }

          const { id, roles, userName, name, phoneNumber } = data;

          commit("SET_USERID", id);
          commit("SET_USERNAME", userName);
          commit("SET_NAME", name);
          commit("SET_PHONE", phoneNumber);
          commit("SET_ROLES", roles);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo;
    return new Promise((resolve, reject) => {
      Cookies.remove(abp.security.antiForgery.tokenCookieName);
      login({ username, password })
        .then(({ result, description }) => {
          if (result === 1) {
            commit("SET_TOKEN", Cookies.get(TokenKey));
            resolve();
          } else {
            reject(new Error(description));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          resetRouter();
          commit("RESET_STATE");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("RESET_STATE");
      resolve();
    });
  },

  setName({ commit }, name) {
    return new Promise((resolve) => {
      commit("SET_NAME", name);
      resolve();
    });
  },

  setTenantName({ commit }, name) {
    return new Promise((resolve) => {
      commit("SET_TENANT_NAME", name);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
