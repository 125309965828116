export function html(text) {
  return text && text.replace(/\r?\n/g, "<br />");
}

export function date(v) {
  if (!v) return v;
  return v.toString().substring(0, 10);
}

export function time(v) {
  if (!v) return v;
  return v.toString().substring(0, 16).replace("T", " ");
}

export function category(v, dict) {
  if (!v) return v;
  const array = typeof v === "string" ? v.split(",") : v;
  return array
    .map((item) => {
      return (dict.find((t) => t.name === item) || {}).displayName;
    })
    .join(",");
}

export function emphasize(v) {
  if (!v) return v;
  const array = typeof v === "string" ? v.split(",") : v;
  return array
    .map((item) => {
      return `【${item}】`;
    })
    .join("");
}
