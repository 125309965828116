import Vue from "vue";
import VueI18n from "vue-i18n";
// import { getApplicationConfiguration } from "@/api/abp";

// import zh from "./zh-CN";

Vue.use(VueI18n);

const messages = {
  "zh-Hans": {},
};

export const getLocale = () => {
  // setTimeout(async () => {
  //   const { localization } = await getApplicationConfiguration();
  //   for (const resource in localization.values) {
  //     Object.assign(messages["zh-Hans"], localization.values[resource]);
  //   }
  //   setLanguage("zh-Hans", messages);
  // }, 0);
  return "zh-Hans";
};

const i18n = new VueI18n({
  locale: getLocale(),
  messages,
});

export function setLanguage(language, values) {
  i18n.mergeLocaleMessage(language, values);
  i18n.locale = language;
}

export default i18n;
