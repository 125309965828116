import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const whiteList = [
  /^\/index/,
  /^\/positions/,
  /^\/admin\/login/,
  /^\/infomation/,
  /^\/about/,
  /^\/public/,
  /^\/admin\/account\/reset-password/,
  /^\/admin\/account\/forget-password/,
];

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "Index",
    component: () => import("../views/Index.vue"),
  },
  {
    path: "/positions",
    name: "Positions",
    component: () => import("../views/Positions.vue"),
  },
  {
    path: "/resume/edit",
    name: "ResumeEdit",
    component: () => {
      return import("../views/resume/Edit.vue");
    },
  },
  {
    path: "/resume/fill-rest",
    name: "ResumeFill",
    component: () => {
      return import("../views/resume/Fill.vue");
    },
  },
  {
    path: "/resume/ok",
    name: "ResumeOK",
    component: () => import("../views/resume/OK.vue"),
  },
  {
    path: "/resume/mine",
    name: "ResumeMine",
    component: () => import("../views/resume/Mine.vue"),
  },
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: () => import("../views/admin/Login.vue"),
  },
  {
    path: "/admin/account/forget-password",
    name: "AdminForgetPassword",
    component: () => import("../views/admin/ForgetPassword.vue"),
  },
  {
    path: "/admin/account/reset-password",
    name: "AdminResetPassword",
    component: () => import("../views/admin/ResetPassword.vue"),
  },
  {
    path: "/admin/account/change-password",
    name: "AdminChangePassword",
    component: () => import("../views/admin/ChangePassword.vue"),
  },
  {
    path: "/infomation",
    name: "Infomation",
    component: () => import("../views/Infomation.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const adminRouters = [
  {
    path: "/admin/resumes",
    name: "AdminResumes",
    component: () => import("../views/admin/Resumes.vue"),
  },
  {
    path: "/admin/positions",
    name: "AdminPositions",
    component: () => import("../views/admin/Positions.vue"),
  },
  {
    path: "/admin/position-providers",
    name: "AdminPositionProviders",
    component: () => import("../views/admin/PositionProviders.vue"),
  },
];

const superAdminRouters = [
  {
    path: "/admin/accounts",
    name: "AdminAccounts",
    component: () => import("../views/admin/Accounts.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (from.query.recruitSource && !to.query.recruitSource) {
    return next({
      ...to,
      query: Object.assign(to.query, {
        recruitSource: from.query.recruitSource,
      }),
      replace: true,
    });
  } else if (store.state.account.token) {
    if (store.state.account.roles && store.state.account.roles.length) {
      return next();
    } else {
      const { roles, userName } = await store.dispatch("account/getInfo");
      if (userName === "admin") {
        superAdminRouters.forEach((r) => {
          router.addRoute(r);
        });
        return next({ ...to, from, replace: true });
      } else if (roles.indexOf("admin") !== -1) {
        adminRouters.forEach((r) => {
          router.addRoute(r);
        });
        return next({ ...to, from, replace: true });
      } else {
        return next();
      }
    }
  } else {
    if (whiteList.some((t) => t.test(to.path))) {
      return next();
    } else if (/^\/admin/.test(to.path)) {
      return next("/admin/login");
    } else {
      return next("/");
    }
  }
});

export function resetRouter() {
  const newRouter = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes,
  });
  router.matcher = newRouter.matcher;
}

export default router;
