<template>
  <v-tooltip
    :top="position === 'top'"
    :right="position === 'right'"
    :bottom="position === 'bottom'"
    :left="position === 'left'"
    :activator="activator"
  >
    <span>{{ content }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "DefaultTooltip",
  props: {
    activator: { required: true },
    content: { type: String, required: true },
    position: { type: String, required: false, default: "top" },
  },
};
</script>
