const abp = {};
(function () {
  /* UTILS ***************************************************/

  abp.utils = abp.utils || {};

  /**
   * Gets a cookie with given key.
   * This is a simple implementation created to be used by ABP.
   * Please use a complete cookie library if you need.
   * @param {string} key
   * @returns {string} Cookie value or null
   */
  abp.utils.getCookieValue = function (key) {
    var equalities = document.cookie.split("; ");
    for (var i = 0; i < equalities.length; i++) {
      if (!equalities[i]) {
        continue;
      }

      var splitted = equalities[i].split("=");
      if (splitted.length !== 2) {
        continue;
      }

      if (decodeURIComponent(splitted[0]) === key) {
        return decodeURIComponent(splitted[1] || "");
      }
    }

    return null;
  };

  /* SECURITY ***************************************/
  abp.security = abp.security || {};
  abp.security.antiForgery = abp.security.antiForgery || {};

  abp.security.antiForgery.tokenCookieName = "XSRF-TOKEN";
  abp.security.antiForgery.tokenHeaderName = "RequestVerificationToken";

  abp.security.antiForgery.getToken = function () {
    return abp.utils.getCookieValue(abp.security.antiForgery.tokenCookieName);
  };
})();

export default abp;
