import Vue from 'vue';
import Vuetify, { VSnackbar, VBtn, VIcon, VTooltip } from 'vuetify/lib';
import zhHans from 'vuetify/lib/locale/zh-Hans';
import VuetifyToast from "vuetify-toast-snackbar-ng";
import VueTooltipDirective from 'vue-tooltip-directive'
import DefaultTooltip from '@/components/DefaultTooltip'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
    VTooltip
  },
});

Vue.use(VuetifyToast);
Vue.use(VueTooltipDirective, {
  component: DefaultTooltip
})

export default new Vuetify({
    lang: {
      locales: { zhHans },
      current: 'zhHans',
    },
});
