import axios from "axios";
import Vue from "vue";
import abp from "./abp";
// import store from "@/store";
import qs from "qs";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (config.method === "get") {
      config.paramsSerializer =
        config.paramsSerializer ||
        ((params) => {
          return qs.stringify(params, { indices: false });
        });
    }

    // do something before request is sent
    const xsrf = abp.security.antiForgery.getToken();
    if (xsrf) {
      config.headers[abp.security.antiForgery.tokenHeaderName] = xsrf;
    }

    const tenant = process.env.VUE_APP_TENANT_ID;
    if (tenant) {
      config.headers.__tenant = tenant;
    }

    // const language = getLanguage();
    // config.headers["Accept-Language"] = language || "zh-Hans";
    config.headers["Accept-Language"] = "zh-Hans";

    // if (store.state.user.token) {
    //   // let each request carry token
    //   config.headers["Authorization"] = "Bearer " + store.state.user.token;
    // }

    if (config.params) {
      const params = Object.assign({}, config.params);

      if (params.itemsPerPage) {
        if (params.itemsPerPage === -1) {
          params.skipCount = 0;
          params.maxResultCount = 1000;
        } else {
          params.skipCount = ((params.page || 1) - 1) * params.itemsPerPage;
          params.maxResultCount = params.itemsPerPage;
        }

        delete params.page;
        delete params.itemsPerPage;
        config.params = params;
      }
      if (Array.isArray(params.sortBy) && params.sortBy.length) {
        const sorting = [];
        for (let i = 0; i < params.sortBy.length; i++) {
          sorting.push(
            `${params.sortBy[i]} ${!params.sortDesc[i] ? "desc" : "asc"}`
          );
        }
        params.sorting = sorting.join(",");
        delete params.sortBy;
        delete params.sortDesc;
      }
      delete params.mustSort;
      delete params.multiSort;
    }

    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

function l(name) {
  return "[" + name + "]";
}

function showError(response) {
  let message = "";
  let title = "";
  if (response.data && response.data.error) {
    if (response.data.error.details) {
      message = response.data.error.details;
      title = response.data.error.message;
    } else if (response.data.error.message) {
      message = response.data.error.message;
    } else if (response.data.error_description) {
      message = response.data.error_description;
      title = response.data.error;
    }
  } else {
    switch (response.status) {
      case 400:
        title = response.data.error;
        message = response.data.error_description;
        break;
      case 401:
        title = l("AbpUi.DefaultErrorMessage401");
        message = l("AbpUi.DefaultErrorMessage401Detail");
        break;
      case 403:
        title = l("AbpUi.DefaultErrorMessage403");
        message = l("AbpUi.DefaultErrorMessage403Detail");
        break;
      case 404:
        title = l("AbpUi.DefaultErrorMessage404");
        message = l("AbpUi.DefaultErrorMessage404Detail");
        break;
      case 429:
        message = l("global.operatingFast");
        break;
      case 500:
        title = l("AbpUi.500Message");
        message = l("AbpUi.InternalServerErrorMessage");
        break;
      default:
        title = response.statusText;
        message = response.status + " " + response.statusText;
        break;
    }
  }

  // console.error(`${title}：${message}`);
  Vue.prototype.$toast(
    `${title}${title ? "：" : ""}${message.replace(/\r\n/g, "<br/>")}`,
    {
      x: "center",
      y: "top",
      color: "error",
      // icon: "mdi-alert-circle",
    }
  );
}

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const data = response.data;

    if (data.__abp) {
      if (data.success === true) {
        return data.result;
      } else if (data.success === false) {
        showError(response);
      }
    }
    return data;
  },
  (error) => {
    console.log(error); // for debug
    showError(error.response);
    return Promise.reject(error);
  }
);

export default service;
