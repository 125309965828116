import request from "@/utils/request";

const BASE_API = "/api/account";

export function phoneSignin({ phoneNumber, code }) {
  return request({
    url: `${BASE_API}/phone/signin`,
    method: "post",
    data: {
      phoneNumber,
      code,
      name: "同学",
    },
  });
}

export function sendPhoneSigninCode({ phoneNumber }) {
  return request({
    url: `${BASE_API}/phone/send-signin-code`,
    method: "post",
    data: {
      phoneNumber,
    },
  });
}

export function login({ username, password }) {
  return request({
    url: `${BASE_API}/login`,
    method: "post",
    data: {
      userNameOrEmailAddress: username,
      password,
    },
  });
}

export function logout() {
  return request({
    url: `${BASE_API}/logout`,
    method: "get",
  });
}

export function getInfo() {
  return request({
    url: `${BASE_API}/current`,
    method: "get",
  });
}

export function getByRole(roleName) {
  return request({
    url: `${BASE_API}/by-role/${roleName}`,
    method: "get",
  });
}

export function sendPasswordResetCode({ email, appName }) {
  return request({
    url: `${BASE_API}/send-password-reset-code`,
    method: "post",
    data: {
      email,
      appName,
    },
  });
}

export function resetPassword({ userId, resetToken, password }) {
  return request({
    url: `${BASE_API}/reset-password`,
    method: "post",
    data: {
      userId,
      resetToken,
      password,
    },
  });
}

export function changePassword({ currentPassword, newPassword }) {
  return request({
    url: `${BASE_API}/my-profile/change-password`,
    method: "post",
    data: {
      currentPassword,
      newPassword,
    },
  });
}
